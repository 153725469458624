export const test = true

export const stripePublicKey = () => (
    test ? "pk_test_9dLZUxQb8LiCGE09kwp3bv6s" : "pk_live_APwapE1cEQEYaWh3E2iUWhA6"
)

export const backendHost = "http://localhost:3450"
//export const backendHost = "https://mj-staging.herokuapp.com"

export const orderStatus = (status) => {
    switch (status) {
        case "paid": return "Enregistrée"
        case "preparing":  return "En préparation"
        case "shipped": return "Expédiée"
        case "delivered": return "Livrée"
        case "returning": return "Retour"
        case "returned": return "Retour"
    }
}