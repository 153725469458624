import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { getInstagramFeedInfo } from "origen-react-instagram-feed"
import store from "../store"
import { Spacer, Loader } from "../components/banners"

// export const init = () => {
//   console.log("Insta init")
//   getInstagramFeedInfo("mariannebymariejordane", {
//     numberOfMediaElements: 3,
//     discardVideos: false,
//   }).then(v => {
//     console.log(v)
//     store.dispatch({ type: "SET_INSTA", insta: v })
//   })
// }

const InstaRow = ({media}) => (
    <div style={{display: 'flex'}}>
        {media.map(({id, thumbnail, postLink}) => (
            <a key={id} style={{padding: '0 15px'}} href={postLink}>
                <img src={thumbnail}></img>
            </a>
        ))}
    </div>
)

const Load = () => (
  <span style={{position: 'relative'}}><Loader /></span>
)

export default () => {
  const data = useSelector(state => state.insta)
  //useEffect(() => { init() }, [])
  console.log("rendering insta", data)
  return (
    <div
      style={{
        textAlign: "center",
        letterSpacing: "0.1em",
        fontStyle: "italic",
      }}
    >
      <div>Notre Instagram</div>
      <Spacer v="1rem" />
      <div>@mariannebymariejordane</div>
      <div>#onfaitdesfringuestropbelles</div>     
       <Spacer v="1rem" />
      { data ? (<InstaRow media={data.media} />) : (<Load />)}
    </div>
  )
}
