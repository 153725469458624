import fetch from "cross-fetch"
import { navigate } from "@reach/router"
import {stripePublicKey, backendHost} from "./config"
import { uuid } from "./utils/utils"
import { getInstagramFeedInfo } from "origen-react-instagram-feed"


const genericError =
  "Une erreur s'est produite, veuillez réessayer ou nous contacter !"
const isClient = typeof window !== "undefined"
const DONT_RENDER = "DONT_RENDER"

const sync = (getState, action, info) => {
    const token = getState().token
    const uid = getState().uid
    const path = isClient && window.location.pathname
    console.log("sync", uid, token, action, info)
  return fetch(backendHost + "/mmj", {
      method: "post",
      body: JSON.stringify({ ...info, token, action, uid, path }),
  })
  .then(f => f.json())
  .catch(e => {
      console.error("sync error", e)
      throw {msg: genericError, error: e}
  })
}

const instaInit = (dispatch) => {
    getInstagramFeedInfo("mariannebymariejordane", {
        numberOfMediaElements: 3,
        discardVideos: false,
    }).then(v => {
        dispatch({ type: "SET_INSTA", insta: v })
    })
}

const refresh = (dispatch, getState) => {
    sync(getState, "refresh")
    .then(f => {
        if (f.ok && f.ok.products) {
            console.log(f.ok.products)
            dispatch({ type: "SET_REFRESH", refresh: f.ok })
        }
    })
}

// triggered after "real" navigation (page refresh)
export const initStore = member => (dispatch, getState) => {
    console.log("initStore")
    if (isClient) {
        refresh(dispatch, getState)
        instaInit(dispatch, getState)
    }
}

const _login = (dispatch, afterLoginPath)  => {
    dispatch({type: "SET_AFTERLOGIN_PATH", afterLoginPath})
    navigate("/login")
}

const _loginSuccess =  (dispatch, getState, data) => {
    const url = getState().afterLogin || "/app/profile"
    dispatch({ type: "LOGIN_SUCCESS", ...data })
    isClient && window.history.replaceState({}, url, url)
    navigate(url)
    return DONT_RENDER
}

export const createMember = member => (dispatch, getState) => {
  return sync(getState, "create_member", member)
    .then(f => {
      if (f.ok) {
          return _loginSuccess(dispatch, getState, f.ok)
      } else {
        switch (f.error) {
          case "exist":
            throw {msg: "Il y a déjà un compte associé à cet email. N'hésitez pas à nous contacter si vous avez perdu votre mot de passe."}
          default:
            throw {msg: genericError}
        }
      }
    })
}

export const login = info => (dispatch, getState) => {
  dispatch({ type: "LOGIN_START" })
  return sync(getState, "login", info)
    .then(f => {
      if (f.ok) {
        return _loginSuccess(dispatch, getState, f.ok)
      } else {
        throw {msg: (f.error == "user")
          ? "Nous n'avons pas de compte associé à cet email 🤔."
          : "Le mot de passe semble incorrect 😰."}
      }
    })
}

export const logout = () => (dispatch, getState) => {
  return sync(getState, "logout")
  .then(() => {
      navigate("/").then(() => dispatch({ type: "LOGOUT_SUCCESS" }))
      return DONT_RENDER
    })
}

export const addToCart = ({product, size}) => (dispatch, getState) => {
    const { name, price, pics, fields } = product
    const line = {
      name: name,
      price: price,
      "shown-price": price, // temp
      thumb: pics[0].localImage.small.fixed,
      link: fields.slug,
      id: uuid(),
      size: size,
    }
    const cart = getState().cart
    return sync(getState, "add_to_cart", {cart, line})
    .then(f => {
        dispatch({type: "SET_CART", cart: f.ok})
    })
}

export const removeFromCart = ({lineId}) => (dispatch, getState) => {
    const cart = getState().cart
    return  sync(getState, "remove_from_cart", {cart, lineId})
    .then(f => {
        dispatch({type: "SET_CART", cart: f.ok})
    })
}

export const submitPromoCode = ({code}) => (dispatch, getState) => {
    const cart = getState().cart
    return  sync(getState, "submit_promo_code", {cart, code})
    .then(f => {
        if (f.ok) {
            dispatch({type: "SET_CART", cart: f.ok})
        } else switch (f.error) {
            case "unknowncode":
                throw {msg: "On dirait que ce code n'existe pas. N'hésitez pas à nous contacter si vous pensez que c'est une erreur."}
            case "alreadyapplied":
                throw {msg: "Déjà appliqué !"}
        }
    })
}

export const removePromoCode = ({code}) => (dispatch, getState) => {
    const cart = getState().cart
    return  sync(getState, "remove_promo_code", {cart, code})
    .then(f => {
        if (f.ok) {
            dispatch({type: "SET_CART", cart: f.ok})
        } 
    })
}

// need to fetch member (and shipping info)
// if not logged-in, go to login
export const startCheckout = () => (dispatch, getState) => {
  if (getState().token) {
    // we could use server's response, but let's skip a roundtrip since it's a normal path
    return sync(getState, "get_loggedin_member")
      .then(f => {
        if (f.ok) {
          dispatch({ type: "GOT_MEMBER", member: f.ok })
          navigate("/shipping")
          return DONT_RENDER
        } else {
          _login(dispatch, "/shipping")
          return DONT_RENDER
        }
      })
  } else {
    _login(dispatch, "/shipping")
    return Promise.resolve(DONT_RENDER)
  }
}

export const setShipping = shipping => (dispatch, getState) => {
  dispatch({ type: "SET_SHIPPING", shipping }) // save to local state
  return sync(getState, "update_loggedin_member", { member: { shipping } })
    .then(f => {
      if (f.ok) {
        dispatch({ type: "GOT_MEMBER", member: f.ok })
        navigate("/payment")
        return DONT_RENDER
      } else {
        switch (f.error) {
          case "notloggedin":
            _login(dispatch, "/shipping")
            return DONT_RENDER
          default:
            throw {msg: genericError}
        }
      }
    })
}

export const createStripeSession = (member, cart) => (dispatch, getState) => {
    //const failingMember = {...member,  shipping: undefined} // test
    return sync(getState, "create_stripe_session", { member, cart, test: true })
      .then(f => {
        if (f.ok) {
            const stripe =  window.Stripe(stripePublicKey())
            stripe.redirectToCheckout({sessionId: f.ok})
            return DONT_RENDER
        } else {
          console.log("stripe error", f.error)
          throw {msg: genericError}
        }
      })
  }
  
export const subscribeNewsletter = (email) => (dispatch, getState) => {
    const context = (isClient && window.location.pathname) || ""
    return  sync(getState, "subscribe_newsletter", {email, context})
    .then(() => { return DONT_RENDER })
}

export const getOrders = () =>  (dispatch, getState) =>  {
    return  sync(getState, "get_loggedin_orders", {})
    .then(f => {
        if (f.ok) {
            dispatch({ type: "SET_ORDERS", orders: f.ok})
        }
    })
}