import { useStaticQuery, graphql } from "gatsby"

export const idToProduct = (data, productId) => {
    const n = data.allMongodbHerokuD589Dff8Mbmjproducts.edges.find((p) => p.node.mongodb_id == productId)
    if (n) {
        return n.node
     } else {
        console.error("Product not found", productId)
        return null
     }
}

export const uuid = () => (
  ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
)

export const trackingUid = () => (
  "100-100".replace(/[01]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
)

export const validEmail = (email) => (
    email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
)
export const validZip = (zip) => (
  zip && /^\d{5}$/.test(zip)
)
export const validString = (string) => (
  typeof string === "string" && string != ""
)

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";
  
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }
  
    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, "") // trim - from start of text
      .replace(/-+$/, ""); // trim - from end of text
  
    return str;
  }

export const getRefreshedProduct = (state, productId) => {
  const ps = state.refresh && state.refresh.products
  if (!Array.isArray(ps)) return
  return ps.find(({_id}) => _id == productId) // int & string
}

export const getUrlVars = () => {
  var vars = {};
  var parts = (typeof window !== "undefined") && window.location.href.replace(/[?&]+([^=&]+)=?([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}