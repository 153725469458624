import React, { useState, Children } from "react"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { validEmail } from "../utils/utils"
import Spinner from "../assets/45.gif"
import "./loader.css"
import InputMask from "react-input-mask"
import { subscribeNewsletter } from "../actions"
import {Submitter} from "../components/submitter"

export const Title = ({children, style, level = 1}) => (
  <div style={{textAlign: 'center', letterSpacing: '0.1em', fontWeight: 'bold', fontSize: level===1 ? '1.5rem' : '1.2rem', marginBottom: '1.5rem', ...style}}>{children}</div>
)

export const Loader = () => (
<div style={{display: 'inline-block'}}>
  <div className="loader" style={{fontSize: '3px'}}>Loading...</div>
</div>
)

const avantages = [
  ["Livraison offerte", "À partir de 200 € d'achats"],
  ["Retours gratuits", "Sous 15 jours en France Métropolitaine"],
  ["Paiment sécurisé", "Visa, Mastercard, Amex, Paypal"],
]

export const Avantages = ({ direction = "row" }) => (
  <div
    style={{
      display: "flex",
      flexDirection: direction,
      justifyContent: "space-around",
      textAlign: "center",
    }}
  >
    {avantages.map(([title, body]) => (
      <div key={title} style={{ marginBottom: "1rem" }}>
        <div
          style={{
            letterSpacing: "0.1em",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          {title}
        </div>
        <div style={{ letterSpacing: "0.1em", fontStyle: "italic" }}>
          {body}
        </div>
      </div>
    ))}
  </div>
)

export const Newsletter = () => {
  const [email, setEmail] = useState("")
  const [done, setDone] = useState(false)
  const dispatch = useDispatch()
  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          letterSpacing: "0.1em",
          fontWeight: "700",
        }}
      >
        Pour ne rien rater de nos actualités
      </div>
      <div
        style={{
          letterSpacing: "0.1em",
          fontStyle: "italic",
          margin: "8px 0 20px 0",
        }}
      >
        Inscrivez-vous à notre newsletter
      </div>
      {!done ? (<div style={{ position: "relative", display: "inline-block" }}>
        <input
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
          spellCheck="false"
          style={{
            width: "300px",
            borderWidth: "0 0 1px 0",
            padding: "0.5rem 2rem",
            outline: "none",
            textAlign: "center",
            fontFamily: "Montserrat",
            letterSpacing: "0.1rem",
          }}
          placeholder="Adresse e-mail"
        />

        {validEmail(email) && (
          <div
            style={{
              position: "absolute",
              top: "120%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Submitter action={() => dispatch(subscribeNewsletter(email)).then((x) => {setDone(true); return x})} >
               <span style={{fontWeight: '500'}}>M'inscrire</span>
            </Submitter>
          </div>
        )}
      </div>) : (<div>Merci !</div>)}
    </div>
  )
}

const contactMargin = "1rem"

export const Contact = () => (
  <div style={{ letterSpacing: "0.1em" }}>
    <div
      style={{ fontWeight: "bold", textAlign: "center", marginBottom: "4rem" }}
    >
      NOUS CONTACTER
    </div>
    <div style={{ width: "1000px", marginLeft: "200px" }}>
      <div style={{ display: "inline-block", width: "33.33%" }}>
        <div style={{ marginBottom: contactMargin }}>info@mariejordane.com</div>
        <div style={{ marginBottom: contactMargin }}>07.68.17.30.73</div>
        <div style={{ marginBottom: contactMargin }}>
          @mariannebymariejordane
        </div>
        <div>@mariannebymariejordane</div>
      </div>
      <div style={{ display: "inline-block", width: "33.33%" }}>
        <div style={{ fontWeight: "bold", marginBottom: contactMargin }}>
          LA BOUTIQUE
        </div>
        <div style={{ marginBottom: contactMargin }}>
          11 Rue Bridaine <br />
          75017 Paris
        </div>
        <div style={{ marginBottom: contactMargin }}>
          Du mercredi au samedi <br />
          De 12h à 19h
        </div>
      </div>
      <div style={{ display: "inline-block", width: "33.33%" }}>
        <div style={{ fontWeight: "bold", marginBottom: contactMargin }}>
          L'ATELIER
        </div>
        <div style={{ marginBottom: contactMargin }}>
          25 bis rue Nollet <br />
          75017 Paris
        </div>
        <div style={{ marginBottom: contactMargin }}>
          Tous les jours <br />
          Sur rendez-vous
        </div>
      </div>
    </div>
  </div>
)

export const NeedHelp = ({className}) => (
  <div className={className} style={{ letterSpacing: "0.1em", textAlign: 'left' }}>
    <div style={{ fontWeight: "bold", marginBottom: '0.2rem' }}>
      Besoin d'aide ?
    </div>
     <div style={{ marginBottom: '0.2rem' }}>info@mariejordane.com</div>
     <div style={{  }}>07.68.17.30.73</div>
  </div>
)

export const Legal = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      letterSpacing: "0.1em",
    }}
  >
    <div>Mentions légales</div>
    <div>Expédition retour</div>
    <div>CGV</div>
  </div>
)

export const Footer = () => (
  <div>
    <Spacer v="8rem"></Spacer>
    <Contact />
    <Spacer v="3rem"></Spacer>
    <Legal />
    <Spacer v="3rem"></Spacer>
  </div>
)

export const Spacer = ({ v }) => <div style={{ marginBottom: v }}></div>

export const Button = ({ onClick, to, children, type = 'primary', style, loader, loading = false }) => (
  <button
    disabled={loading}
    style={{
      width: (type == 'transparent') ? 'auto' : 'auto',
      backgroundColor: {primary: 'black', secondary: 'white', transparent: 'transparent'}[type],
      color: (type == 'primary') ? "white" : 'black',
      textTransform: (type == 'transparent') ? 'default' : "uppercase",
      padding: (type == 'transparent') ? '4px' : "8px 45px",
      letterSpacing: (type == 'transparent') ? 'normal' :  "0.1em",
      border: "none",
      fontWeight: (type == 'transparent') ? '300' : "500",
      border: (type == 'transparent') ? 'none' : "1px solid black",
      cursor: (loading ? 'default' : 'pointer'),
      position: 'relative',
      ...style,
    }}
    onClick={
      onClick ||
      (() => { if (to) { navigate(to) } else { alert("Fonction pas encore implémentée.")}})
    }
  >
    <div style={{opacity: (loading ? 0 : 1.0)}}>
      { children }
    </div>
    { loading &&
      (loader || <img src={Spinner} style={{position: 'absolute', right: '5px', top: '5px', }}/>)
    }
  </button>
)

export const Input = ({ name, label, placeholder, transform = (x) => x, type = 'text', subLabel, value, onChange, error, style }) => {
  return (
    <div>
      <label
        htmlFor={name}
        style={{
          display: "block",
          textTransform: "uppercase",
          fontSize: "0.9em",
        }}
      >
        {label}
      </label>
      <input
        type={type}
        spellCheck={false}
        name={name}
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => onChange(transform(e.target.value))}
        style={{
          width: '100%',
          padding: "8px",
          fontFamily: "Montserrat",
          letterSpacing: "0.1rem",
          ...style,
        }}
      />
      { error && (
        <div style={{fontSize: '0.9em', color: 'red'}}>😰 {error}</div>
      )}
       { !error && subLabel && (
        <div style={{fontSize: '0.9em'}}>{subLabel}</div>
      )}
    </div>
  )
}

export const DateInput = ({ name, label, placeholder, transform = (x) => x, type = 'text', subLabel, value, onChange, error, style }) => {
  return (
    <div>
      <label
        htmlFor={name}
        style={{
          display: "block",
          textTransform: "uppercase",
          fontSize: "0.9em",
        }}
      >
        {label}
      </label>
      <InputMask
        mask="99/99/9999"
        maskPlaceholder="jj/mm/aaaa"
        spellCheck={false}
        name={name}
        value={value || ""}
        onChange={(e) => onChange(transform(e.target.value))}
        style={{
          width: '100%',
          padding: "8px",
          fontFamily: "Montserrat",
          letterSpacing: "0.1rem",
          ...style,
        }}
      />
      { error && (
        <div style={{fontSize: '0.9em', color: 'red'}}>😰 {error}</div>
      )}
       { !error && subLabel && (
        <div style={{fontSize: '0.9em'}}>{subLabel}</div>
      )}
    </div>
  )
}

export const Radios = ({ name, buttons, value, onChange }) => (
  <div>
    {buttons.map(({val, label}) => (
      <span key={val} style={{marginRight: '2rem'}}>
        <input type="radio" 
        name={name}
        value={val}
        id={name + val} 
        checked={value === val}
        onChange={(e) => onChange(e.target.value)} 
        style={{
          
          cursor: 'pointer',
          
          marginRight: '1rem'}} />
                <label htmlFor={name + val}>{label}</label>

      </span>

    ))}
  </div>
)
