import thunkMiddleware from "redux-thunk"
import { createStore, applyMiddleware, compose } from "redux"
import { initStore } from "./actions"
import { trackingUid } from "./utils/utils"

const checkState = (state) => {
  const valid = state.cart && state.cart.items && state.cart.codes
  return valid ? state : undefined
}

const loadState = () => {
  console.log("loadState")
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return undefined
    }
    const state = JSON.parse(serializedState)
    return checkState(state)
  } catch (err) {
    return undefined
  }
}

const saveState = state => {
  console.log("saving state")
  const cleanedState =  {
    ...state,
  }
  try {
    const serializedState = JSON.stringify(cleanedState)
    localStorage.setItem("state", serializedState)
  } catch {
    // ignore write errors
  }
}

const defaultState = {
  v: 1,
  cart: { items: [], codes: [] },
  isClient: (typeof window === "object"),  // used?
}

const maybeAddUid = (state) => (
  { 
    ...state,
    uid: state.uid || ((typeof window === "object") ? trackingUid() : "000-000")
  }
)

  const initialState = maybeAddUid(loadState() || defaultState)


  const rootReducer = (state = initialState, action) => {
    console.log("Reducing: ", action.type)
    switch (action.type) {

      case "SET_CART": {
        return {
          ...state,
          cart: action.cart,
        }
      }
      case "SET_ORDERS": {
        return {
          ...state,
          orders: action.orders,
        }
      }
      case "SET_REFRESH": {
        return {
          ...state,
          refresh: action.refresh, // should we store?
        }
      }
      case "THANK_YOU": {
        return {
          ...state,
          cart: { items: []},
          thankYouCart: state.cart,
        }
      }

      case "SET_SHIPPING": {
        return {
          ...state,
          member: {...state.member, shipping: action.shipping},
        }
      }
      case "SET_AFTERLOGIN_PATH": {
        return {
          ...state,
          afterLogin: action.afterLoginPath,
        }
      }
      case "SET_INSTA":
        return {
          ...state,
          insta: action.insta,
        }
      case "LOGIN_START":
        return {
          ...state,
        }
      case "LOGIN_SUCCESS":
        return {
          ...state,
          token: action.token,
          member: action.member,
          afterLogin: undefined,
        }
        case "GOT_MEMBER":
            return {
              ...state,
              member: action.member,
            }
     
      case "LOGOUT_SUCCESS":
        return {
          ...state,
          token: undefined,
          member: undefined,
          orders: undefined,
        }

      case "@@INIT": // redux internal?
        return state
      default:
        console.error("unknown action", action.type)
        return state
    }
  }

  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose

  const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware)
    // other store enhancers if any
  )

  const store = createStore(rootReducer, initialState, applyMiddleware(thunkMiddleware))

  // maybe throttle this later: https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e
  store.subscribe(() => {
    saveState(store.getState())
  })

  // store is only built after "real" browser navigation, not client-side nav
  store.dispatch(initStore())

  export default store
//   return store
// }

//export default createStore(rootReducer);
