import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "../components/banners"
import styled, { keyframes } from "styled-components"
import Tada from "@bit/formidablelabs.react-animations.tada"
import HeadShake from "@bit/formidablelabs.react-animations.head-shake"

const HeadShakeAnimation = keyframes`${HeadShake}`
const TadaAnimation = keyframes`${Tada}`
const ErrorContainer = styled.div`
  animation: 1s ${props => (props.error ? HeadShakeAnimation : null)};
`

export const Submitter = ({ error, action, children, loader, buttonType = 'primary', style }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    var _mounted = true

    useEffect(() => {
        console.log("mount")
        _mounted = true
        return function cleanup() {
            console.log("dismount")
            _mounted = false   
        }
    }, []) // only on dismount
 
    const submit = () => {
        setLoading(true)
        action().then((result) => {
            console.log("load done", result, _mounted)
            if ((result != "DONT_RENDER") && _mounted) {setLoading(false)}
        })
    }
  return (
    <div style={{display: 'inline-block', ...style}}>
      <ErrorContainer error={error}>
        <Button onClick={submit} loading={loading} loader={loader} type={buttonType} style={{width: '100%'}}>
          {children}
        </Button>
      </ErrorContainer>
      {error && error != true && ( // true means shake but no message
        <div style={{ fontSize: "0.9rem", color: "red", marginTop: "0.5rem" }}>
          {error}
        </div>
      )}
    </div>
  )
}
